import { Box, Stack } from "@mui/material"
import { styled } from "@mui/material/styles"
import { ReactNode } from "react"
import { Logo } from "app/components/Logo"

export const LoggedOutLayout = ({ children }: LoggedOutLayoutProps) => {
  return (
    <Container>
      <Stack spacing={4} sx={{ width: "90%", maxWidth: 400 }}>
        <Logo />
        {children}
      </Stack>
    </Container>
  )
}

export type LoggedOutLayoutProps = {
  children: ReactNode
}

const Container = styled(Box)(() => ({
  display: "grid",
  placeItems: "center",
  height: "100vh",
}))
