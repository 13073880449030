import { Box, Button, Typography } from "@mui/material"
import { AutoForm } from "app/components/Form/AutoForm"
import { useRouter } from "app/components/Router"
import { LoggedOutLayout } from "app/components/LoggedOutLayout"
import type { Components, FormSchema } from "@w3rone/json-schema-form"

export const UserSecurityLoginPage = ({
  csrfToken,
  lastUsername,
  error,
}: UserSecurityLoginPageProps) => {
  const router = useRouter()

  return (
    <LoggedOutLayout>
      {error ? (
        <Typography sx={{ textAlign: "center", color: "error.main" }}>
          {error}
        </Typography>
      ) : null}
      <Box>
        <AutoForm
          schema={schema}
          model={{
            username: lastUsername,
            _remember_me: true,
            _csrf_token: csrfToken,
          }}
          components={{ SubmitButtonWrapper }}
          submitLabel={"Connexion"}
        />
      </Box>
      <Button href={router.generate("app_security_forgotten")}>
        Mot de passe oublié
      </Button>
    </LoggedOutLayout>
  )
}

export type UserSecurityLoginPageProps = {
  csrfToken: string
  lastUsername: string
  error?: string
}

const SubmitButtonWrapper: Components["SubmitButtonWrapper"] = ({
  children,
}) => {
  return (
    <Box sx={{ mt: 4, display: "flex", flexDirection: "column" }}>
      {children}
    </Box>
  )
}

const schema: FormSchema = {
  $id: "",
  $schema: "",
  title: "",
  options: {
    form: { method: "POST", async: false, action: "" },
    layout: "default",
    widget: "login",
  },
  type: "object",
  properties: {
    username: {
      title: "Email",
      type: "string",
      options: {
        widget: "text",
        layout: "default",
      },
    },
    password: {
      title: "Mot de passe",
      type: "string",
      options: {
        widget: "password",
        layout: "default",
      },
    },
    _remember_me: {
      title: "Se souvenir de moi",
      type: "boolean",
      options: {
        widget: "checkbox",
        layout: "default",
      },
    },
    _csrf_token: {
      title: "",
      type: "string",
      options: {
        widget: "hidden",
        layout: "default",
      },
    },
  },
  required: [],
}
